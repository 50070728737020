@import '../../mixins.scss';
@import '../../variables.scss';

.experience {
  margin-bottom: 3%;
}

.experience-details {
  @include row-between;
}

.company-name {
  text-decoration: underline;
}