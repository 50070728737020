@import '../../variables.scss';
@import '../../mixins.scss';

.contact-section {
  @include column-center;
  width: 100vw;
  margin-top: 3%;
  font-family: $signika;
}

.contact-card {
  @include blue-on-white;
  @include column-center;
  width: 50%;
  box-shadow: 4px 5px 20px black;
  padding: 3% 0;
  
}

.contact-card h2 {
  text-align: center;
  text-decoration: underline wavy;
  margin-top: 0;
  font-size: 2em;
}

.contact-options {
  align-self: flex-start;
  margin: 0 10%;
}

@media only screen and (max-width: 1000px) {
  .contact-card {
      width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .contact-card {
    width: 80%;
    margin-top: 5%;
  }

  .contact-card h2 {
    font-size: 1.25em;
  }
}