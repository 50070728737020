@import '../../variables.scss';
@import '../../mixins.scss';

.nav-background {
  @include column-center;
  width: 100%;
}

.nav-bar {
  @include column;
  background: url(../navBarImages/dirtyPaper.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 2% 0;
  box-shadow: 0px 2px 20px black;
  color: $navy;
  width: 80vw;
  border-radius: 8px;
  font-family: $signika;
  margin: 3% 0;
}

.nav-intro {
  @include column;
}

.nav-intro h1 {
  font-size: 2.5em;
  margin: 0
}

.current-description {
  border-bottom: .5px $navy dotted;
  padding-bottom: 2px;
}

.nav-intro h3 {
  margin-top: 0;
}

.nav-btn-container {
  display: flex;
  flex-wrap: wrap;
  width: 15%;
  height: 50%;
  align-self: center;
  justify-content: flex-end;
}

.nav-btn {
  width: 100px;
  margin: 2%;
  cursor: pointer;
  @include yellow-button;
  padding: 3%;
  text-align: center;
  text-decoration: none;
}

.nav-content {
  display: flex;
  justify-content: space-around;
}

.section-title {
  align-self: flex-end;
  margin: 0 10%;
  text-decoration: underline wavy;
}

.mobile-section-title {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .nav-bar {
    width: 90vw;
  }
}

@media only screen and (max-width: 1000px) {
  .nav-btn-container {
    width: 25%;
  }
}

@media only screen and (max-width: 900px) {
  .nav-btn-container {
    justify-content: center;
    width: 30%;
  }

  .section-title {
    display: none;
  }

  .mobile-section-title {
    display: flex;
    margin: 20px 0 0 0;
    text-decoration: underline wavy;
  }
}

@media only screen and (max-width: 700px) {
  .nav-bar {
    width: 100vw;
    margin-top: 0;
    border-radius: 0;
  }

  .nav-btn-container {
    width: 35%;
  }
  
  .nav-intro {
    margin-left: 3%;
  }
}

@media only screen and (max-width: 600px) {
  .nav-btn-container {
    @include column-center;
    align-self: flex-start;
    margin-left: 20px;
  }

  .nav-intro h3, .nav-intro h2 {
    font-size: 1em;
  }

  .nav-intro h1 {
    font-size: 1.75em;
  }

  .nav-btn {
    width: 80px;
    font-size: .80em;
    margin: 5% 0;
  }
}

@media only screen and (max-width: 360px) {
  .nav-btn {
    width: 65px;
    font-size: .70em;
  }
}