@import '../../variables.scss';
@import '../../mixins.scss';

.adventure-cover {
  @include column-center;
  @include blue-on-white;
  width: 25%;
  margin: 2%;
  padding: 1%;
  box-shadow: 4px 5px 20px black;
}

.adventure-img-cover {
  width: 100%;
  height: 60%;
}

.adventure-cover h4 {
  margin: 1%;
}

@media only screen and (max-width: 1200px) {
  .adventure-cover {
    width: 40%;
    margin: 2%;
  }
}

@media only screen and (max-width: 900px) {
  .adventure-img-cover {
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .adventure-cover {
    width: 80%;
    margin: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .adventure-cover {
    padding-bottom: 10%;
  }
}

@media only screen and (max-width: 400px) {

  .adventure-cover h4 {
    font-size: .8em;
  }
}