@import '../../mixins.scss';

.pdf-btn-container-bottom {
  @include column-center;
  margin-top: 5%;
}

.pdf-btn-container-top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 2%;
}

.pdf-download {
  @include yellow-button;
  padding: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
    .pdf-download {
    font-size: .75em;
  }
}

