@import '../../variables.scss';
@import '../../mixins.scss';

.photos-section {
  @include column-center;
  font-family: $signika;
  width: 100vw;
}

.adventure-library {
  @include row-wrap-center;
}

.error-message {
  color: $navy;
  font-size: 2em;
  text-decoration: underline wavy;
}

@media only screen and (max-width: 700px) {
  .adventure-library {
    margin-bottom: 10%;
  }
}
