@import '../../variables.scss';
@import '../../mixins.scss';

.resume-section {
  @include column-center;
  font-family: $signika;
  width: 100%;
  margin-bottom: 5%;
}

.resume-page {
  background-color: $base;
  width: 70%;
  padding: 5%;
  border-radius: 5px;
  box-shadow: 5px 7px 18px black;
  margin-bottom: 0;
  margin-top: 3%;
}

.resume-page h3 {
  margin-bottom: .5%;
  color: $maroon;
}

.resume-page h4 {
  margin: 0;
}

.resume-page p {
  margin-top: .5%;
}

.projects-title, .education-title {
  margin-top: 5%;
  color: $navy;
}

.experience-title {
  color: $navy;
}

@media only screen and (max-width: 700px) {
  .resume-page {
    margin-top: 10%;
    margin-bottom: 10%;
        width: 80%;
  }
  .projects-title, .education-title {
    margin-top: 8%;
  }
}