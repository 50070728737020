@import '../../variables.scss';

.adventure-filter {
  width: 30%;
  align-self: flex-start;
  margin-top: 3%;
  margin: 3% 5% 5% 5%;
  background-color: $base;
  padding: 1% 2%;
  border-radius: 10px;
  box-shadow: 4px 6px 10px black;
}

.adventure-filter h2 {
  color: $navy;
  margin-top: 0;
}

.adventure-filter select {
  width: 90%;
  padding: 2% 5%;
  font-size: 16px;
  outline: none;
  margin-bottom: 3%;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .adventure-filter {
    width: 45%;
  }
}

@media only screen and (max-width: 800px) {
  .adventure-filter {
    width: 70%;
    align-self: center;
    margin-top: 5%;
  }

  .adventure-filter h2 {
    font-size: 1em;
    margin-top: 1%;
  }
}